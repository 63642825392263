<template>
  <QHeader/>
  <NuxtPage/>
  <QCartGlobal/>
  <QFooter/>
  <Toast group="app"/>
</template>

<script setup lang="ts">
</script>
